// import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueScrollTo from 'vue-scrollto'

import VueGtag from "vue-gtag";

// import '@popperjs/core'
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// import '@/assets/main.css'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap'

// import 'bootstrap-vue/dist/bootstrap-vue.css'



createApp(App).use(router).use(VueScrollTo).use(VueGtag, {
    config: { id: "G-FER7QR1P8E" }
  }, router).mount('#app')


// app.use(BootstrapVue)

// Make BootstrapVue available throughout your project
// App.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)